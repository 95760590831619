
.libraryHome {
	width: 90vw;
	margin: 0 auto;
}

.libraryHome::before {
	content: '';
	display: block;
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-color: rgba(192,215,203, 1);
}

.library-shelf-wrap::after {
	content: '';
	display: block;
	width: 90vw;
	height: 12px;
	background-color: rgb(111,79,72);
	clear: both;
	display: flex;
	margin-top: 2px;
}

.library-movie-category {
  font-family: 'Compagnon Medium';
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
  text-align: center;
}

.library-shelf {
	display: flex;
	flex-direction: row;
}

.library-shelf .libraryVideo {
	padding: 5px 12px;
	width: 16px;
	height: 500px;
	margin-right: 4px;
	transition: 200ms;
}

.library-shelf .libraryVideo:hover {
	filter: brightness(1.3);

}
.library-shelf .libraryVideo a {
	display: block;
	height: 100%;
	width: 100%;
	white-space: nowrap;
	text-indent: 14px;
	color: rgb(4,45,116);
}

.library-shelf .libraryVideo a span {
	transform: rotate(90deg);
	display: block;
}

.libraryVideo:nth-child(1) {
	background-color: rgb(252,220,0);
}

.libraryVideo:nth-child(2) {
	background-color: rgb(253,177,184);
	text-transform: uppercase;
	letter-spacing: 2px;
}

.libraryVideo:nth-child(3) {
	background-color: rgb(0,174,65)
}

.libraryVideo:nth-child(4) {
	background-color: rgb(244,178,32)
}

.libraryVideo:nth-child(5) {
	background-color: rgb(49,47,32);
}

.libraryVideo:nth-child(5) a {
	color: rgb(244,244,246);
	letter-spacing: 2px;
	text-transform: uppercase;
}

.libraryVideo:nth-child(6) {
	background-color: rgb(194,213,2);
}

.libraryVideo:nth-child(7) {
	background-color: rgb(0,127,111)
}

.libraryVideo:nth-child(8) {
	background-color: rgb(255,91,54)
}

.libraryVideo:nth-child(9) {
	background-color: rgb(253,177,184);
	text-transform: uppercase;
}

.libraryVideo:nth-child(10) {
	background-color: rgb(0,174,65)
}

.libraryVideo:nth-child(11) {
	background-color: rgb(244,178,32)
}

.libraryVideo:nth-child(12) {
	
}
