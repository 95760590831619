@font-face {
  font-family: 'Compagnon Roman';
  src: url('./fonts/Compagnon-Roman.woff2') format('woff2'),
       url('./fonts/Compagnon-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-kerning: normal;
  font-display: block; 
}

@font-face {
  font-family: 'Compagnon Medium';
  src: url('./fonts/Compagnon-Medium.woff2') format('woff2'),
       url('./fonts/Compagnon-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-kerning: normal;
  font-display: block; 
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-variant-ligatures: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


nav {
  font-family: 'Compagnon Medium';
  text-transform: uppercase;
  width: 100vw;
  margin: 0 auto;
  letter-spacing: 0.1rem;
  padding: 2rem 0;
  padding-left: 2rem;
  position: fixed;
  top: 0;
  transition-property: all;
  transition-duration: 400ms;
  user-select: none;
}

.navbar {
  /* ... */
  user-select: none;
}

.navbar.scrolled {
  padding: 1rem 0;
  padding-left: 2rem;
  background: rgb(13,31,63);
}



.navbar.scrolled a {
    color: white;
}



nav a {
  padding-right: 4rem;
  text-decoration: none;
  color: #121212;
}

nav a:hover {
  color: rgb(202, 26, 89);
}

nav a.active {
  text-decoration: underline;
}

.home .navbar {
  background: transparent;
}

.home .navbar a {
  color: white;
}

.home .navbar.scrolled {
  background: #fefefe;
}

.home .navbar.scrolled a {
  color: #121212;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.store-wrap {
  width: 90vw;
  margin-top: 10vh;
  margin-left: auto;
  margin-right: auto;
}

.store-wrap ul {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;

}

.store-wrap ul li {
  width: calc(25% - 20px);
  margin-bottom: 20px;
  text-align: left;
}

.store-wrap ul li img {
  display: block;
  max-width: 100%;
}

.homeProjectsWrap, .homeEventsWrap, .homeStoreWrap {

  margin-right: 0px;
  user-select: none;
  margin-top: 0;
  overflow-x: hidden;
}
.homeProjectsWrap .home-project a {
  color: rgb(13,32,63);
}
.homeProjectsWrap {
  padding-top: 2vh;
  background-color: rgba(196,206,211,0.2);
}

.homeProjectsWrap h2 {
  font-family: Compagnon Medium;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 4vh;
}

.homeProjectsWrap h2 a {
  color: rgb(13,32,63);
}

.homeMoreWork {
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100vw;
  height: 40vh;
  font-weight: normal;
}

.homeMoreWork a {
  color: rgb(13,31,63);
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-bottom: 0;
  margin-right: 20px;
  background-image: url('./images/see-more.svg');;
  align-items: center;
  text-align: center;
  background-repeat: repeat;
  background-position: center;
  padding: 0;
  margin: 0;
  transition: 100ms;
}

.homeMoreWork a:hover, .homeMoreWork a:active {
  background-image: url('./images/see-more-b.svg');;
}

.homeMoreWork a span {
  visibility: hidden;
}
.homeMoreWork a:hover {
  color: rgb(202, 26, 89);
}

ul.work-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  user-select: none;
}


.workProjectsWrap {
  padding-left: 20px;
  padding-bottom: 9vh;
}


ul.work-projects li p {
  text-align: center;
  font-size: 1.1rem;
  padding: 14px 34px 0 34px;
}

ul.work-projects li span {
  color: rgb(180,180,180);
  display: block;
}


ul.home-projects p {
  text-align: center;
  padding: 24px 24px 0 24px;
}

ul.home-projects span {
  color: rgb(180,180,180);
  display: block;
}

@media (max-width: 1400px) {
  ul.home-projects li {
    width: 100vw;
  }
}



@media (max-width: 767px) {
  body, html {
    width: 100vw !important;
    overflow-x: hidden;
  }
  ul.home-projects, ul.work-projects {
    grid-template-columns: repeat(1, 1fr) !important; 
    margin: 0 auto !important;
    gap: 20px;
  }

  .workProjectsWrap, .homeProjectsWrap {
    padding-left: 0 !important;
  }
  ul.home-projects li, ul.work-projects li {
    width: 100%;
  }

  ul.work-projects li {
    padding-bottom: 2vh;
  }
  ul.home-projects li.project-4, ul.work-projects li.project-4 {
    width: 100%;
  }

  .homeProjectsWrap h2 {
    font-size: 1.2rem;
    letter-spacing: 2px;
    padding-bottom: 1vh;
    padding-top: 0.5vh;
  }

  .projectWrap p {
    width: 90vw !important;
    margin: 24px auto;
    font-size: 1.12rem;
  }

  .projectWrap h3 {
    font-weight: normal;
    padding-top: 4vh;
    padding-bottom: 0vh;
    padding-left: 8px;
  }
  .projectWrap h3 span {
    display: block;
  }
  figcaption {
    font-size: 0.6rem !important;
    margin: 6px 0 !important;
  }
  .videoContainer {
    width: 100%;
    height: auto;
    margin: 0rem 0 0 -10% ;
    padding: 60% !important;
  }
  .projectWrap {
    width: 95vw !important;
    margin: 0 auto;
  }
  .store-wrap ul li {
    width: calc(50% - 20px);
    margin-bottom: 20px;
    text-align: left;
  }
}

@media (max-width: 390px) {
  .store-wrap ul li {
    width: calc(100% - 20px);
    margin-bottom: 20px;
    text-align: left;
  }
}

@media (min-width: 2007px) {
  ul.home-projects {

  }
}

ul.work-projects li:nth-child(1) {
  column-span: 3;
}

ul.home-projects li a, ul.work-projects li a {
  color: #121212;
}

ul.home-projects {
  gap: 0;
}
ul.home-projects li {
  margin-left: 0;
}

ul.home-projects li a {
  width: 100%;
}

ul.home-projects li img.turnip {
  aspect-ratio: 16 / 9;
  width: 80vw;
  object-fit: cover;
  margin-top: 14vh;
  position: relative;
  left: 10.5vw;
  border-radius: 4px;
}

@media (max-width: 470px) {
  ul.home-projects li img.turnip {
    aspect-ratio: 1 / 1;
  }
}


ul.home-projects li.project-psnc-rich-drama {
  background-color: rgb(0, 149, 110);

}


ul.home-projects li.project-rhode-island-blood-center {
  background-color: rgb(219,222,219);

}

ul.home-projects li.project-ridoh-dont-tempt-2020 {
  background-color: rgb(30,67,136);
}

ul.home-projects li.project-ridoh-dont-tempt-2020 a, ul.home-projects li.project-ridoh-dont-tempt-2020 .homeProjectTitle {
  color: white;
}

ul.home-projects li.project-ridoh-dont-tempt-2020 span {
  border-color: white;
}

ul.home-projects li.project-psnc-rich-drama a {

}

ul.work-projects li img {
  display: block;
  max-width: 100%;
  border-radius: 4px;
  width: 95%;
  margin: 0 auto;
}
h2, h3, h4, h5, h6, p, ul {
  font-family: 'Compagnon Roman', sans-serif;
  font-weight: normal;
}

h4 {
  font-family: 'Compagnon Medium', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.3rem;
  letter-spacing: 0.1rem;
  margin-top: 0.3rem;
}


p {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 500;
}

p + p {
  margin-top: 1rem;
}

h3 + p {
  width: 80vw;
  margin: 5vh 0 0 0 !important;

}

h4 + p {
  margin-bottom: 0.6rem;
}

a {
  text-decoration: none;
}

.navbarWrapper {
  z-index: 1000;
  position: fixed;
  text-align: center;
}

.home-zero {
  width: 100vw;
  height: 95vh;
  padding-bottom: 0rem;
  background-color: rgb(14,31,63);
  top: 0;
  margin-bottom: 0rem;
}

.projectWrap {
  width: 80vw;
  margin: 5vh auto 5vh auto;

}

.aboutWrap {
  width: 60vw;
  margin: 5vh auto 20vh auto;
}

.aboutWrap a {
  text-decoration: underline;
}

aboutWrap a:hover {
  text-decoration: none;
}


.projectWrap:first-of-type, .aboutWrap:first-of-type{
  margin-top: 12vh;
}

.projectWrap img {
  max-width: 100%;
}

.projectWrap p {
  width: 60vw;
  margin: 24px auto;
  line-height: 1.6rem;
}
.projectWrap h3 span {
  color: #959595;
}

.invisible {
  width: 0;
  height: 0;
  overflow: hidden;
  display: none;
}

figcaption {
  font-family: 'Compagnon Medium', sans-serif;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 86%;
  margin-left: 1rem;
  line-height: 1rem;
  margin-top: 1rem;
  color: #777;
}


.category-tags {
  width: 70vw;
  margin: 13vh auto 4vh auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}

.category-tags button {
  font-family: 'Compagnon Medium', sans-serif;
  background: none;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 4px 8px 6px 8px;
  color: rgb(31,34,39);
  cursor: pointer;
  transition: 300ms;
  font-size: 0.7rem !important;
}

.category-tags button:hover {
  background-color: rgb(202, 26, 89);
  color: white;
}

.category-tags button.active {
  background: #3c3c3c;
  border-color: #9d9d9d;
  color: white;
}

ul.categories li {
  padding: 6px 12px 8px 12px;
  margin: 4px 12px;
  border: 1px #3c3c3c solid;
  border-radius: 4px;
  font-size: 0.8rem;
}

ul.categories li:hover {
  background: #3c3c3c;
  color: white;
}

.strikethrough {
  font-weight: 600;
  text-decoration: line-through;
}

.product-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 40px;
  user-select: none;
}

.product-page .product-images {
  width: calc(60% - 40px);
}

.product-page .product-images img {
  width: 100%;
}

.product-page .product-info {
  width: calc(40% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prouduct-page .product-info div {
  display: block;
}

.product-info p {
  width: auto;
}



/* fade in effects */


/* Define the animation */
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to the list items */
ul.home-projects li {
  opacity: 0;
  animation: fade-in 0.5s ease-in-out forwards;
}

ul.work-projects li {
  margin-bottom: 10px;
}

/* Delay each list item's animation */
ul.work-projects li:nth-child(1) {
  animation-delay: 0s;
}
ul.work-projects li:nth-child(2) {
  animation-delay: 0.05s;
}
ul.work-projects li:nth-child(3) {
  animation-delay: 0.1s;
}
ul.work-projects li:nth-child(4) {
  animation-delay: 0.15s;
}
ul.work-projects li:nth-child(5) {
  animation-delay: 0.2s;
}
ul.work-projects li:nth-child(6) {
  animation-delay: 0.25s;
}
ul.work-projects li:nth-child(7) {
  animation-delay: 0.3s;
}
ul.work-projects li:nth-child(8) {
  animation-delay: 0.35s;
}
ul.work-projects li:nth-child(9) {
  animation-delay: 0.4s;
}
ul.home-projects li:nth-child(10), ul.work-projects li:nth-child(10) {
  animation-delay: 0.45s;
}
ul.home-projects li:nth-child(11), ul.work-projects li:nth-child(11) {
  animation-delay: 0.5s;
}
ul.home-projects li:nth-child(12), ul.work-projects li:nth-child(12) {
  animation-delay: 0.55s;
}
ul.home-projects li:nth-child(13), ul.work-projects li:nth-child(13) {
  animation-delay: 0.6s;
}
ul.home-projects li:nth-child(14), ul.work-projects li:nth-child(14) {
  animation-delay: 0.65s;
}
ul.home-projects li:nth-child(15), ul.work-projects li:nth-child(15) {
  animation-delay: 0.7s;
}
ul.home-projects li:nth-child(16), ul.work-projects li:nth-child(16) {
  animation-delay: 0.75s;
}
ul.home-projects li:nth-child(17), ul.work-projects li:nth-child(17) {
  animation-delay: 0.8s;
}
ul.home-projects li:nth-child(18), ul.work-projects li:nth-child(18) {
  animation-delay: 0.85s;
}
ul.home-projects li:nth-child(19), ul.work-projects li:nth-child(19) {
  animation-delay: 0.9s;
}
/* Continue with the same pattern for the other list items */

.standard-grid {
  display: flex;
  flex-wrap: wrap;
  width: 80vw auto;
  background: transparent;
  justify-content: center;
  align-items: center;
}

.standard-grid img {
  flex: 0 0 auto;
  margin: 14px;
  border-radius: 4px;
  width: 25%;
  height: 25%;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  width: 80vw auto;
  background: rgb(255,255,255);
  justify-content: center;
  align-items: center;
}

.image-grid img {
  flex: 0 0 auto; /* Allow images to have their natural width */
  margin: 20px; /* Add spacing between images */
  border-radius: 4px;
}

.image-grid img:nth-child(1),
.image-grid img:nth-child(2),
.image-grid img:nth-child(5),
.image-grid img:nth-child(6),
.image-grid img:nth-child(7),
.image-grid img:nth-child(8),
.image-grid img:nth-child(10),
.image-grid img:nth-child(11),
.image-grid img:nth-child(13),
.image-grid img:nth-child(14),
.image-grid img:nth-child(16),
.image-grid img:nth-child(17),
.image-grid img:nth-child(19)
 {
  width: 25%; /* Set width for the first two images */
  height: 25%;
}

.image-grid img:nth-child(3),
.image-grid img:nth-child(4),
.image-grid img:nth-child(9),
.image-grid img:nth-child(11),
.image-grid img:nth-child(12),
.image-grid img:nth-child(15),
.image-grid img:nth-child(17),
.image-grid img:nth-child(18),
.image-grid img:nth-child(20),
.image-grid img:nth-child(21) {
  width: 35%; /* Set width for the next three images */
  height: 35%;
}

.image-grid img:selector:nth-child(3n+3) {
  width: 25%; /* Set width for the first two images */
  height: 25%;
}

.image-grid img:selector:not(:nth-child(3n+3)) {
  width: 25%; /* Set width for the next three images */
  height: 25%;
}

selector:nth-child(3n+3) {
  width: 35%; /* Set width for the next three images */
  height: 35%;
}

.image-wrap img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-grid.quad-grid img:nth-child(1),
.image-grid.quad-grid img:nth-child(2),
.image-grid.quad-grid img:nth-child(3),
.image-grid.quad-grid img:nth-child(4) {
  width: 45%;
  height: auto;
}

@media (max-width: 767px) {
  .image-grid img:nth-child(1),
  .image-grid img:nth-child(2),
  .image-grid img:nth-child(3),
  .image-grid img:nth-child(4),
  .image-grid img:nth-child(5),
  .image-grid img:nth-child(6),
  .image-grid img:nth-child(7),
  .image-grid img:nth-child(8),
  .image-grid img:nth-child(9),
  .image-grid img:nth-child(10),
  .image-grid img:nth-child(11),
  .image-grid img:nth-child(12),
  .image-grid img:nth-child(13),
  .image-grid img:nth-child(14),
  .image-grid img:nth-child(15),
  .image-grid img:nth-child(16),
  .image-grid img:nth-child(17),
  .image-grid img:nth-child(18),
  .image-grid img:nth-child(19),
  .image-grid img:nth-child(20),
  .image-grid img:nth-child(21) {
    width: 90%;
    width: 90%;
  }
  .standard-grid img {
    width: 90%;
    height: 90%;
  }
  .ridoh-video-grid { 
    grid-template-columns: repeat(1, 1fr) !important; 
  }
  .aboutWrap {
    margin-top: 4vh;
    width: 80vw;
  }
  .aboutWrap p {
    font-size: 1.1rem;
  }
  .image-grid.quad-grid img:nth-child(1),
  .image-grid.quad-grid img:nth-child(2),
  .image-grid.quad-grid img:nth-child(3),
  .image-grid.quad-grid img:nth-child(4) {
    width: 90%;
    height: auto;
  }
}



@media (min-width: 1400px) {
  .home-zero .second-image {
    max-width: 1400px;
    margin: 0 auto;
  }
  .homeWorkInner {
    max-width: 1400px;
    margin: 0 auto;
  }

  ul.home-projects li img.turnip {
    aspect-ratio: 16 / 9;
    width: 1000px;
    object-fit: cover;
    margin-top: 14vh;
    position: relative;
    left: 200px;
    border-radius: 4px;
  }
  .homePostText {
    width: 1010px !important;
    position: relative;
    left: 200px !important;
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
  }

  .image-grid {
    max-width: 1400px;
    margin: 0 auto;
  }
  .projectWrap {
    max-width: 1400px !important;
  }
  .projectWrap p {
    width: 900px;
  }

  .workProjectsWrap {
    width: 1400px;
    left: 0;
    margin: 0 auto;
  }

  ul.work-projects {
    width: 1400px !important;
  }
}


.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: background 500ms;
}

.lightbox-overlay .close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
}

.lightbox-overlay img {
  max-width: 90%;
  max-height: 80vh !important;
  height: auto !important;
  width: 90vw !important;
  object-fit: contain;
}


.carousel-status {
  display: block;
  top: auto !important;
  bottom: 0px !important;
  left: 50%;
  width: 14vw;
  white-space: nowrap;
  text-align: center;
  font-size: 0.9rem !important;
  color: rgb(133,133,133) !important;
  font-weight: 600;
  text-shadow: none !important;
  transform: translateX(-7vw) translateY(-4vh);
}


.carousel {
  max-width: 100vw;
  padding-bottom: 10vh;
  cursor: grab !important;
}
.carousel .slide {
  padding: 10px; /* Add space around each slide */
  cursor: grab !important;
}

.carousel .slide img {
  object-fit: contain; /* Make sure images scale properly */
  cursor: grab !important;
}

.carousel .center .slide img {
  max-height: 90%; /* Decrease the size of the centered image */
  cursor: grab !important;
}

.carousel .slide {
  display: flex; 
  align-items: center;
  justify-content: normal !important;
  height: 100%; /* Make sure the slide is full height */
  cursor: grab !important;
}

.carousel .slide img {
  max-height: 100%; /* Ensure the image doesn't overflow */
  cursor: grab !important;
}


.carousel .control-prev.control-arrow:before,
.carousel .control-next.control-arrow:before {
  border-width: 3px; /* adjust arrow thickness */
}

.carousel .control-arrow {
  width: 20%; /* adjust button width */
  height: 100%; /* adjust button height */
  background-color: rgba(0,0,0,0); /* adjust button background */
}

.carousel.carousel-slider .control-arrow:hover {
  background-color: rgba(0,0,0,0) !important;
  opacity: 0 !important;
}

.carousel.carousel-slider .control-arrow:active {
  background-color: rgba(0,0,0,0) !important;
  opacity: 0 !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  background-color: rgba(0,0,0,0) !important;
  opacity: 0 !important;
}

.lightbox-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lightbox-caption {
  margin-top: 10px;
  text-align: center;
  color: white;
  width: 60vw;
}

.videoWrap video {
  width: 50vw;
  height: auto;
  border-radius: 4px;
  margin: 0 auto;
}


.ridoh-video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  grid-gap: 40px;
  margin: 0 50px;
}

.ridoh-video video {
  width: 100%;
}
#root {

}

.footer {
  bottom: 0;
  padding-top: 0vh;
  padding-bottom: 0vh;
  align-items: center;
  top: 95vh;
  text-align: center;
  margin-left: 0rem;
}

.social-links {
  font-family: 'Compagnon Roman';
  display: flex;
  text-align: center;
}

.small-text p {
  font-family: 'Compagnon Medium';
  font-weight: normal;
  color: rgb(213,211,233);
  font-size: 0.8rem;
  letter-spacing: 2px;
  line-height: 2rem;
  text-align: center;
}

.social-links a {
  color: rgb(13,31,63);
  padding: 0 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}


ul.work-projects {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
  grid-gap: 10px; 
  width: 90vw;
  margin: 0 auto;
}

ul.work-projects li, ul.home-projects li {
  text-align: left;
  transition: 500ms;
}



ul.work-projects li:hover, ul.home-projects li:hover {
  cursor: pointer;
}

ul.work-projects li:hover img, ul.home-projects li:hover img {

  cursor: pointer !important;
}

.portrait-photo {
  width: 100vw;
  height: 90vh;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.libraryHome {
  position: relative;
  top: 10vh;
}

.overlay {
  background: rgba(13,14,22,0.7);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}
.video-container {
  position: fixed;
  padding-top: calc(100% / (16 / 9)); /* Adjust the aspect ratio here */

}

button.videoClose {
  position: fixed;
  bottom: 6vh;
  left: 50%;
  z-index: 10000;
  background: transparent;
  border: none;
  font-family: 'Compagnon Medium';
  color: rgb(244,244,255);
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.video-container > div {
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
}


.homeBrand {
  width: 200px;
  left: 0;
  padding: 0;
  position: relative;
}

.navText {
  top: -12px;
  position: relative;
}

.navbar:nth-child(1) {

}

.homeBrand.active {
  text-decoration: none !important;
}

.homeBrand span {
  visibility: hidden;
  width: 0;
  position: absolute;
}

.homeBrand::after {
  content: '';
  display: inline-block;
  left: 0;
  margin-right: 65px;
  width: 180px; /* Adjust the width of the image */
  height: 36px; /* Adjust the height of the image */
  background-image: url('./images/holland-nav.svg'); /* Replace with the path to your image */
  background-size: contain;
  background-position: center center; /* Adjust the background-size property if needed */
  background-repeat: no-repeat; /* Adjust the background-repeat property if needed */
  /* Add any other styles you want for the image replacement */
}

nav.navbar.scrolled a.homeBrand.active::after, .navbarWrapper.work nav.navbar a.homeBrand::after, .navbarWrapper.Unknown nav.navbar a.homeBrand::after {
  background-image: url('./images/holland-nav.svg');
  filter: brightness(0.2);
}

.navbarWrapper.work nav.navbar.scrolled a.homeBrand::after, .navbarWrapper.Unknown nav.navbar.scrolled a.homeBrand::after  {
  filter: brightness(1);
}

.homePostText {
  width: 75vw;
  position: relative;
  left: 12.5vw;
  display: flex;
  padding-bottom: 10vh;
  justify-content: space-between;
}


.homePostText span {
  flex-grow: 1;
  border-bottom: 1px black solid;
  margin: -20px 0px 10px 0px;
}


.homePostText > p {
  flex-grow: 0;
}

.homeBlurb {
  margin-bottom: 3px;
  text-align: right;
}

.homePostText .homeProjectTitle {
  text-align: left;
  color: rgb(12,17,14);
  font-family: 'Compagnon Medium';
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1rem;
  margin-top: 3px;
}


@media (max-width: 465px) {
  .homeBrand::after {
    content: '';
    display: inline-block;
    left: 0;
    margin-right: 2rem;
    width: 155px; /* Adjust the width of the image */
    height: 35px; /* Adjust the height of the image */
  }
  nav a {
    padding-right: 2rem;
    font-size: 0.8rem;
  }

  .navbar {
    padding-left: 0.8rem;
  }
  nav.navbar.scrolled {
    padding-left: 0.8rem;
  }
  .projectWrap {
    padding: 0 1.4rem 0 1.4rems;
  }



  .homePostText {
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center the elements horizontally */
    padding-top: 20px;
    padding-bottom: 10vh;
    text-align: center;
  }


  .homePostText p, .homePostText span {
    flex-grow: 0; /* Reset flex-grow property to prevent expanding */
    margin: 0; /* Reset margin */
    border-bottom: none; /* Remove the dotted border */
    text-align: center;
  }

  .homePostText .homeProjectTitle {
    text-align: center;
  }

  .homePostText p {
    padding: 0 !important;
  }


}



.fade-in {
  opacity: 0;
  transform: translateY(20px); 
  animation: fade-in-animation 1s ease forwards;
  animation-fill-mode: forwards;
}

@keyframes fade-in-animation {
  0% {
    opacity: 0;
    transform: translateY(30px); /* Adjust the desired initial position */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.second-image {
  position: relative;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.initial-class {
  background-image: url('./images/holland3.svg');

}

.class1 {
  background-image: url('./images/holland_1a_h.svg');
}

.class2 {
  background-image: url('./images/holland_1b_h.svg');
}

.class3 {
  background-image: url('./images/holland3.svg');
}

@media (max-width: 767px) {
  .class1 {
    background-image: url('./images/holland_1a_v.svg');
  }

  .class2 {
    background-image: url('./images/holland_1b_v.svg');
  }
}