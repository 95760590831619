
.m_container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
}

.m_mockeryWrap {
  width: 90vw;
  position: relative;
  margin: 10vh auto;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.m_mockeryFrame {
	width: 750px;
	height: 1542px;
	position: absolute;
	z-index: 1;
}


.m_form {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Compagnon Roman';
  align-items: right;
}

.m_form div {
	width: 470px;
}

.m_form label {
	font-family: 'Compagnon Medium';
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 12px;
  display: inline-block;
  width: 25%; /* Set a fixed width for the labels */
  text-align: right; /* Align labels to the right */
  margin-bottom: 10px;
  user-select: none;
  margin-right: 10px;
}

.m_numeric_input {
	border: none;
	font-family: 'Compagnon Roman' !important;
	font-size: 16px;
	background: rgb(220,220,220,0.8);
}

.m_text_input {
	font-family: 'Compagnon Roman' !important;
	border: none;
	font-size: 16px;
	background: rgb(220,220,220,0.8);
}


.m_numeric_input:focus, .m_text_input:focus {
	outline: none;
	background: rgb(240,240,240,0.7);
	color: rgb(13, 31, 63);
}
.m_form input,
.m_form textarea {
  width: 70%;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.m_save {
	width: 300px;
	margin-left: 130px;
	height: 40px;
	margin-top: 32px;
	outline: 1px solid rgb(13, 31, 63);
	border-radius: 5px;
	background-color: transparent;
	color: rgb(13, 31, 63);
	font-family: 'Compagnon Medium' !important;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 10px;
	letter-spacing: 2px;
}

.m_save:active {
	background-color: rgb(202, 26, 89);
}

.m_mockup {
  flex: 1;

}

.m_phoneWrap {
	zoom: 50%;
	left: 4vw;
	position: relative;
	user-select: none;
}

.m_phoneInner {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  width: 702px;
  height: 1519px;

}

.m_phoneContent {
  position: relative;
  left: 20px;
  border: 1px black solid;
  border-radius: 100px;
  box-shadow: 0px 40px 120px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  height: 100%;
  top: 20px;
}


.video_container {
	z-index: 0;
	position: absolute;
	top: 0;
	left: 10px;
	width: 100%;
	height: 100%;
	border-top-left-radius: 100px;
	border-top-right-radius: 100px;
	overflow: hidden;
}

.video_container video {
	width: auto;
	height: 1380px;
	margin-left: -45px;
	filter: contrast(1.05) brightness(0.95);
}
.video_container .m_shadowOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 140px;
  z-index: 1; /* Make sure the overlay appears behind the video */
  box-shadow: inset 0px -200px 220px rgba(0, 0, 0, 1), /* Edges shadow */
             inset 0px 0px 220px rgba(0, 0, 0, 1); /* Bottom shadow */
}

.m_ui {
	position: relative;
	top: 0;
	z-index: 100;
	color: white;
	height: 100%;
}

.m_icon {
	width: 64px;
	height: 64px;
}

.m_phoneWrap ul li {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.m_leftSidebar {
  position: absolute;
  top: 640px;
  right: 40px;
  display: flex;
  flex-direction: column;
  width: 60px;
}

.m_leftSidebar div {
	align-items: center;
	text-align: center;
	margin-bottom: 20px;
}

.m_leftSidebar div p {
	font-family: 'ProximaNova-Regular', 'Proxima Nova Regular';
	margin-top: 8px;
	font-size: 22px;
	text-align: center;
	display: block;
	width: 85px;
}

.m_leftSidebar div span {
	visibility: hidden;
	width: 0;
	display: block;
	position: absolute;
}

.m_sidebarAvatar {
	width: 88px;
	height: 88px;
	border-radius: 80px;
	margin-bottom: 10px;
	border: 3px rgb(220,220,220) solid;
	margin-left: -10px;
	position: relative;
}

.m_leftSidebar div:nth-child(1):after {
	content: '+';
	display: block;
	position: absolute;
	width: 40px;
	height: 40px;
	top: 74px;
	left: 18px;
	font-size: 30px;
	border-radius: 20px;
	background-color: red;
}
.m_sidebarIcon {
	width: 66px;
	opacity: 0.8;
	border-radius: 0px;
	margin-left: 10px;
}

.m_overlayLayoutAssist {
	top: 0;
	left: 10px;
	width: 100%;
	position: absolute;
	opacity: 0;
}

.m_topMenu {
  width: 720px;
  position: absolute;
  margin: 120px 5px;
  display: flex;
  justify-content: space-between;
  /* If you want to align items vertically, use the following property */
  align-items: center;
  user-select: none;
}

.m_topMenu li {
  font-family:  'ProximaNova-Bold', 'Proxima Nova Regular', 'Comic Sans', Chalkboard, handwritten !important;
  font-size: 34px;
}
.m_topMenu li span.search {
	visibility: hidden;
}

.m_topMenu li span.live {
	visibility: hidden;
}

.m_topMenu li.selected {
	opacity: 1;
	position: relative;
}

.m_topMenu li.selected:after {
	content: '';
	position: absolute;
	border-bottom: 3px white solid;
	width: 40px;
}
.m_topMenu li:nth-child(2) {
	margin-left: 40px;
	opacity: 0.6;
}

.m_topMenu li:nth-child(3) {
	position: relative;
}
.m_topMenu li:nth-child(3)::after {
	content: '';
	width: 50px;
	border: 4px white solid;
	position: absolute;
	left: 28px;
	top: 48px;
}
.m_bottomBar {
	position: absolute;
	bottom: 240px;
	left: 40px;
}

.m_bottomBar h3 {
	font-family: 'ProximaNova-Regular', 'Proxima Nova Regular';
	font-size: 34px;
	text-stroke: 1px white;
}

.m_bottomBar p {
	font-family: 'ProximaNova-Regular', 'Proxima Nova Regular';
	font-size: 30px;
	width: 480px;
}

.m_tiktokMenu {
	position: absolute;
	width: 720px;
	bottom: 0;
	overflow: hidden;
	border-bottom-left-radius: 120px;
	border-bottom-right-radius: 120px;
}

.m_tiktokMenu img {
	width: 720px;
}

.m_uploadVideo {
	background-color: rgb(155,155,156, 0.2);
	padding: 12px 24px;
}

@media (max-width: 767px) {
	.m_phoneWrap { 
		zoom: 40%;
	}
	.m_mockeryWrap {
		flex-direction: column;
		align-items: flex-end;
		order: 1;
	}
	.m_container {
		flex-direction: column;

	}
	.m_form {
		order: 1;
		margin-bottom: 10vh;
	}
	.m_form div {
		width: 350px;
	}
	.m_mockup {
		margin: 5vh auto 5vh auto;
		padding-right: 5vw;
		left: 0;
		order: 0;
	}
}